import React, { useEffect } from "react";
import "./styles.css"; // Assuming the CSS file is in the same directory
import { Link } from "react-router-dom";
import BmbSpaceSuitImage from "./img/bmb_spacesuit.png";
import BearthstoneBearsImage from "./img/sapphire_bears_main.JPG";
import BlueMoonBearsImage from "./img/blue_moon_bears.png";
import SpaceToSkyImage from "./img/space_to_sky.png";
import NaturesBeansImagePNG from "./img/natures_beans.png";
import NaturesBeansImageJPG from "./img/natures_beans.jpg";
import JellyBeanSunsetImage from "./img/jelly_bean_sunset_2.png";
import SkyImage from "./img/sky.png";
import SpaceImage from "./img/space.png";
import FroggoFrontImage from "./img/froggo_and_stickers.JPG";
import InstaImage from "./img/insta.png";
import SweetPeaImage from "./img/sweet_pea_specials.png";

const Home = () => {
  useEffect(() => {
    document.documentElement.scrollIntoView({ behavior: "instant" });
  }, []);

  return (
    <>
      <div
        className="background"
        style={{
          backgroundImage: `url(${SpaceImage})`,
          overflow: "hidden",
          backgroundSize: "cover",
        }}
      >
        <header className="masthead" style={{ overflow: "visible" }}>
          <div className="container text-center p-5">
            <div className="container justify-content-center d-flex mb-4">
              <h3 className="fw-bold speech-left fadeInUp-animation">
                Ground control to blue moon bear, do you copy?
              </h3>
            </div>
            <div className="container justify-content-center d-flex mb-5">
              <h3 className="fw-bold speech-right fadeInUp-animation-delayed">
                Loud and clear! The blue moon bears have landed!
              </h3>
            </div>
            <div className="container justify-content-center d-flex mb-5">
              <img
                className="justify-content-center vh-100"
                style={{ bottom: 0 }}
                src={BmbSpaceSuitImage}
                alt="blue moon bear in spacesuit"
              />
            </div>
          </div>
        </header>
        <section className="page-section cta">
          <div className="container">
            <div className="product-item">
              <div className="product-item-title d-flex">
                <div className="bg-faded p-5 d-flex me-auto rounded">
                  <h2 className="section-heading mb-0">
                    <span className="section-heading-upper">Valentine's Day</span>
                    <span className="section-heading-lower text-lowercase">
                      Sweet pea specials
                    </span>
                  </h2>
                </div>
              </div>
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={SweetPeaImage}
                alt="blue moon bears"
              />
              <div className="product-item-description d-flex ms-auto">
                <div className="bg-faded p-5 rounded">
                  <p className="mb-3">
                    Take <b>20% off</b> all of our sweet pea products when you apply the code <b>SWEETPEA</b> at checkout! Eligible items include our sweet pea plushie, peas be mine embroidery, and peas be mine sticker.
                  </p>
                  <div className="text-center intro-button mx-auto">
                    <Link
                      className="btn btn-primary btn-xl"
                      to={"/catalog/sweetpea"}
                    >
                      Shop now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="page-section">
          <div className="container">
            <div className="product-item">
              <div className="product-item-title d-flex">
                <div className="bg-faded p-5 d-flex me-auto rounded">
                  <h2 className="section-heading mb-0">
                    <span className="section-heading-upper">The original</span>
                    <span className="section-heading-lower text-lowercase">
                      blue moon bears
                    </span>
                  </h2>
                </div>
              </div>
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={BlueMoonBearsImage}
                alt="blue moon bears"
              />
              <div className="product-item-description d-flex ms-auto">
                <div className="bg-faded p-5 rounded">
                  <p className="mb-3">
                    These little blue bears have traveled the galaxy far and
                    wide and are ready for a new home! Small but mighty, the
                    blue moon bears are known for being incredibly friendly and
                    great tinkerers. Don't be surprised if a few tools go
                    missing, or a partially assembled rocket ship appears in
                    your home!
                  </p>
                  <div className="text-center intro-button mx-auto">
                    <Link
                      className="btn btn-primary btn-xl"
                      to={"/products/prod_Qetz8w48DvShCP"}
                    >
                      Shop now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="page-section">
          <div className="container">
            <div className="product-item">
              <div className="product-item-title d-flex">
                <div className="bg-faded p-5 d-flex ms-auto rounded">
                  <h2 className="section-heading mb-0">
                    <span className="section-heading-upper">birthstone inspired bears</span>
                    <span className="section-heading-lower text-lowercase">
                      bearthstone collection
                    </span>
                  </h2>
                </div>
              </div>
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={BearthstoneBearsImage}
                alt="bearthstone collection sapphire bears"
              />
              <div className="product-item-description d-flex me-auto">
                <div className="bg-faded p-5 rounded">
                  <p className="mb-3">
                  These baby bears are inspired by each month’s birthstone. Big fan of astrology? We also have varieties with lil star sign tatts!
                  </p>
                  <div className="text-center intro-button mx-auto">
                    <Link
                      className="btn btn-primary btn-xl"
                      to={"/catalog/bearthstone"}
                    >
                      Shop the collection
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        className="background"
        style={{
          backgroundImage: `url(${SpaceToSkyImage})`,
          overflow: "hidden",
        }}
      >
        <section className="page-section">
          <div className="container">
            <div className="product-item">
              <div className="product-item-title d-flex">
                <div className="bg-faded p-5 d-flex me-auto rounded">
                  <h2 className="section-heading mb-0">
                    <span className="section-heading-upper">
                      Made to cuddle instead of cook
                    </span>
                    <span className="section-heading-lower text-lowercase">
                      <img
                        className="collection-img img-fluid mb-3 mb-lg-0 rounded"
                        src={NaturesBeansImagePNG}
                        alt="nature's beans logo"
                      />
                    </span>
                  </h2>
                </div>
              </div>
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={NaturesBeansImageJPG}
                alt="nature's beans"
              />
              <div className="product-item-description d-flex ms-auto">
                <div className="bg-faded p-5 rounded">
                  <p className="mb-3">
                    Looking for something a bit more earthly? These snuggly
                    beans are inspired by what you might find in the kitchen.
                  </p>
                  <div className="text-center intro-button mx-auto">
                    <Link
                      className="btn btn-primary btn-xl"
                      to={"/catalog/beans"}
                    >
                      Shop the collection
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        className="background"
        style={{ backgroundImage: `url(${SkyImage})`, overflow: "hidden" }}
      >
        <section className="page-section">
          <div className="container">
            <div className="product-item">
              <div className="product-item-title d-flex">
                <div className="bg-faded p-5 d-flex ms-auto rounded">
                  <h2 className="section-heading mb-0">
                    <span className="section-heading-upper">
                      Satisfy your sweet tooth
                    </span>
                    <span className="section-heading-lower text-lowercase">
                      Jelly beans
                    </span>
                  </h2>
                </div>
              </div>
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={JellyBeanSunsetImage}
                alt="jelly beans"
              />
              <div className="product-item-description d-flex me-auto">
                <div className="bg-faded p-5 rounded">
                  <p className="mb-3">
                    With fun colors and happy smiles, these jelly beans are sure
                    to make your days sweeter.
                  </p>
                  <div className="text-center intro-button mx-auto">
                    <Link
                      className="btn btn-primary btn-xl"
                      to={"/catalog/beans"}
                    >
                      Shop the collection
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="page-section">
          <div className="container">
            <div className="product-item">
              <div className="product-item-title d-flex">
                <div className="bg-faded p-5 d-flex me-auto rounded">
                  <h2 className="section-heading mb-0">
                    <span className="section-heading-upper">Stickers</span>
                    <span className="section-heading-lower text-lowercase">
                      & more
                    </span>
                  </h2>
                </div>
              </div>
              <img
                className="product-item-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0"
                src={FroggoFrontImage}
                alt="froggo"
              />
              <div className="product-item-description d-flex ms-auto">
                <div className="bg-faded p-5 rounded">
                  <p className="mb-3">
                    Explore what else blue moon bear LLC has to offer.
                  </p>
                  <div className="text-center intro-button mx-auto">
                    <Link
                      className="btn btn-primary btn-xl"
                      to={"/catalog/andmore"}
                    >
                      Shop now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="page-section cta">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 mx-auto">
                <div className="cta-inner bg-faded text-center rounded">
                  <h2 className="section-heading mb-4">
                    <span className="section-heading-upper">
                      Stay up-to-date on all things
                    </span>
                    <span className="section-heading-lower text-lowercase">
                      blue moon bear
                    </span>
                  </h2>
                  <a href="https://www.instagram.com/bluemoonbearshop/">
                    <img
                      className="w-25 mb-4 rounded"
                      src={InstaImage}
                      alt="instagram logo"
                    />
                  </a>
                  <p className="mb-0">
                    Follow us on instagram for new product launches, restocks of
                    your faves, and other exciting updates!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
